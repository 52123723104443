/* ready */
$(function() {
  'use strict';

  /* news */
  $('.p-top-news__tab li').click(function() {
    var tabNumber = $('.p-top-news__tab li').index(this);
    // category
    $('.p-top-news__tab li').removeClass('is-current');
    $(this).addClass('is-current');
    // content
    $('.p-news__content > div').hide();
    $('.p-news__content > div:eq(' + tabNumber + ')').show();
  });
});