/* ready */
$(function() {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function() {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({scrollTop:position}, speed, 'swing');
    return false;
  });


  /* gnavi */
  $('.l-header__gt__menu').click(function() {
    $('.l-header__gt__close, .l-gnavi').fadeIn();
  });
  $('.l-header__gt__close__btn').click(function() {
    $('.l-header__gt__close, .l-gnavi').fadeOut();
  });
});